import { useThree } from "@react-three/fiber";
import { HemisphereLight } from "three";

export default function SunLight() {
  const { scene } = useThree();
  const HLight = new HemisphereLight(0x292929, 0x292929, 0.1);
  HLight.translateY(-6);

  scene.add(HLight);
  return null;
}
