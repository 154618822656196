/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { getMouseDegrees } from "../../shared/hooks/useMouseDegree";

function moveJoint(mouse, joint, degreeLimit = 15, positionX) {
  let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);
  joint.rotation.xD = THREE.MathUtils.lerp(
    joint.rotation.xD || 0,
    degrees.y,
    0.1
  );
  joint.rotation.yD = THREE.MathUtils.lerp(
    joint.rotation.yD || 0,
    degrees.x,
    0.1
  );
  joint.rotation.zD = THREE.MathUtils.lerp(
    joint.rotation.zD || 0,
    positionX < -7 ? degrees.x : -degrees.x * 3,
    0.1
  );
  joint.rotation.x = THREE.Math.degToRad(joint.rotation.xD);
  joint.rotation.y = THREE.Math.degToRad(joint.rotation.yD);
  joint.rotation.z = THREE.Math.degToRad(joint.rotation.zD);
}

export default function Model({ isLoadedModel, setIsLoadedModel }) {
  const group = useRef();
  const HRef = useRef();
  const IRef = useRef();
  const DRef = useRef();
  const positionH = new THREE.Vector3();
  const positionI = new THREE.Vector3();
  const positionD = new THREE.Vector3();
  const { nodes, animations } = useGLTF("/assets/models/hid_web.glb");
  const { actions } = useAnimations(animations, group);
  const [mixer] = useState(() => new THREE.AnimationMixer());
  const { size, viewport } = useThree();
  const strokeRightRef = useRef();
  const strokeLeftRef = useRef();
  useEffect(() => {
    actions["H|CINEMA_4D_Main|Layer0"].setLoop(THREE.LoopOnce);
    actions["H|CINEMA_4D_Main|Layer0"].timeScale = 3;
    actions["i|CINEMA_4D_Main|Layer0"].setLoop(THREE.LoopOnce);
    actions["i|CINEMA_4D_Main|Layer0"].timeScale = 3;
    actions["D|CINEMA_4D_Main|Layer0"].setLoop(THREE.LoopOnce);
    actions["D|CINEMA_4D_Main|Layer0"].timeScale = 3;
  }, [actions]);

  useFrame((state, delta) => mixer.update(delta));

  useEffect(() => {
    if (!isLoadedModel) {
      setIsLoadedModel(true);
    }
  }, [isLoadedModel, setIsLoadedModel]);

  const strokeRightMaterial = new THREE.MeshStandardMaterial({
    color: "#FF310D",
    // ...nodes.Sweep_1_2.material,
    transparent: true,
    side: THREE.DoubleSide,
    alphaTest: 0,
    opacity: 1,
    roughness: 0.5,
  });

  const strokeLeftMetarial = new THREE.MeshStandardMaterial({
    color: "#FF310D",
    // ...nodes.Sweep_2.material,
    transparent: true,
    side: THREE.DoubleSide,
    alphaTest: 0,
    opacity: 1,
    roughness: 0.5,
  });

  // this image is loaded as data uri. Just copy and paste the string contained in "image.src" in your browser's url bar to see the image.
  // alpha texture used to regulate transparency
  const imageRight = document.createElement("img");

  let timeRight = 0;
  let isRightPause = true;
  let timeLeft = 0;
  let isLeftPause = false;
  let changeH = 0;
  let isUpH = false;
  let changeI = 2;
  let isUpI = false;
  let changeD = 4;
  let isUpD = false;
  const alphaMapRight = new THREE.Texture(imageRight);
  imageRight.onload = function () {
    alphaMapRight.needsUpdate = true;
  };
  imageRight.src =
    // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAGCAAAAADMTBDuAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABBJREFUCB1j3M3MaMXMgAQAECEA/lptYgQAAAAASUVORK5CYII=";
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAACpJREFUGJVjvH379n8GPIBl48aN+OQZGG1sbPCawMjAwIBXARNe8+mjAABz4ghlXDzN8gAAAABJRU5ErkJggg==";
  strokeRightMaterial.alphaMap = alphaMapRight;
  strokeRightMaterial.alphaMap.magFilter = THREE.NearestFilter;
  strokeRightMaterial.alphaMap.wrapT = THREE.RepeatWrapping;
  strokeRightMaterial.alphaMap.repeat.y = 1;

  const imageLeft = document.createElement("img");
  const alphaMapLeft = new THREE.Texture(imageLeft);
  imageLeft.onload = function () {
    alphaMapLeft.needsUpdate = true;
  };
  imageLeft.src =
    // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAGCAAAAADMTBDuAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABBJREFUCB1j3M3MaMXMgAQAECEA/lptYgQAAAAASUVORK5CYII=";
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAACpJREFUGJVjvH379n8GPIBl48aN+OQZGG1sbPCawMjAwIBXARNe8+mjAABz4ghlXDzN8gAAAABJRU5ErkJggg==";
  strokeLeftMetarial.alphaMap = alphaMapLeft;
  strokeLeftMetarial.alphaMap.magFilter = THREE.NearestFilter;
  strokeLeftMetarial.alphaMap.wrapT = THREE.RepeatWrapping;
  strokeLeftMetarial.alphaMap.repeat.y = 1;

  useFrame((state, delta) => {
    if (strokeRightRef.current) {
      if (!isRightPause) {
        if (timeRight < 162) {
          strokeRightMaterial.opacity = 1;
          timeRight++;
        } else {
          strokeRightMaterial.opacity = 0;
          isRightPause = true;
        }
        strokeRightRef.current.material.alphaMap.offset.y = -timeRight * 0.007;
      }
    }
    if (strokeLeftRef.current) {
      if (timeLeft < 162) {
        strokeLeftMetarial.opacity = 1;
        timeLeft++;
      } else {
        strokeLeftMetarial.opacity = 0;
        if (!isLeftPause) {
          isLeftPause = true;
          timeRight = 25;
          isRightPause = false;
          setTimeout(() => {
            timeLeft = 25;
            isLeftPause = false;
          }, 5000);
        }
      }
      strokeLeftRef.current.material.alphaMap.offset.y = -timeLeft * 0.007;
    }
    const mouse = {
      x: size.width / 2 + (state.mouse.x * size.width) / 2,
      y: size.height / 2 + (-state.mouse.y * size.height) / 2,
    };
    if (HRef.current) {
      positionH.setFromMatrixPosition(HRef.current.matrixWorld);
      HRef.current.position.z = THREE.MathUtils.lerp(
        HRef.current.position.z,
        // mouse.x / 2,
        changeH,
        0.05
      );
      if (isUpH) {
        if (changeH <= 8) {
          changeH += 0.1;
        } else {
          changeH -= 0.1;
          isUpH = false;
        }
      } else {
        if (changeH >= -8) {
          changeH -= 0.05;
        } else {
          changeH += 0.05;
          isUpH = true;
        }
      }
      if (
        Math.abs(positionH.y - state.mouse.y * viewport.height) < 8 &&
        state.mouse.x * viewport.width < -2 &&
        state.mouse.x * viewport.width > -12
      ) {
        mixer.update(delta);
        moveJoint(mouse, HRef.current, 15, state.mouse.x * viewport.width);
      } else {
        mixer.update(delta);
        moveJoint(mouse, HRef.current, 0, state.mouse.x * viewport.width);
      }
    }

    if (IRef.current) {
      positionI.setFromMatrixPosition(IRef.current.matrixWorld);
      IRef.current.position.z = THREE.MathUtils.lerp(
        IRef.current.position.z,
        // mouse.x / 2,
        changeI,
        0.05
      );
      if (isUpI) {
        if (changeI <= 9) {
          changeI += 0.13;
        } else {
          changeI -= 0.13;
          isUpI = false;
        }
      } else {
        if (changeI >= -9) {
          changeI -= 0.065;
        } else {
          changeI += 0.065;
          isUpI = true;
        }
      }
      // console.log("x", state.mouse.x * viewport.width);
      if (
        Math.abs(positionI.y - state.mouse.y * viewport.height) < 10 &&
        state.mouse.x * viewport.width < 3 &&
        state.mouse.x * viewport.width > -3
      ) {
        mixer.update(delta);
        moveJoint(mouse, IRef.current, 10, state.mouse.x * viewport.width);
      } else {
        mixer.update(delta);
        moveJoint(mouse, IRef.current, 0, state.mouse.x * viewport.width);
      }
    }

    if (DRef.current) {
      positionD.setFromMatrixPosition(DRef.current.matrixWorld);
      DRef.current.position.z = THREE.MathUtils.lerp(
        DRef.current.position.z,
        // mouse.x / 2,
        changeD,
        0.05
      );
      if (isUpD) {
        if (changeD <= 8) {
          changeD += 0.085;
        } else {
          changeD -= 0.085;
          isUpD = false;
        }
      } else {
        if (changeD >= -8) {
          changeD -= 0.04;
        } else {
          changeD += 0.04;
          isUpD = true;
        }
      }
      if (
        Math.abs(positionD.y - state.mouse.y * viewport.height) < 7 &&
        state.mouse.x * viewport.width < 10 &&
        state.mouse.x * viewport.width > 2
      ) {
        // console.log('yes')
        mixer.update(delta);
        moveJoint(mouse, DRef.current, 15, state.mouse.x * viewport.width);
      } else {
        mixer.update(delta);
        moveJoint(mouse, DRef.current, 0, state.mouse.x * viewport.width);
      }
    }
  });

  var material = new THREE.MeshStandardMaterial({
    ...nodes.D_2.material,
    // color: '#595959',
    metalness: 0.875, // between 0 and 1
    roughness: 0.289, // between 0 and 1
  });
  return (
    <group ref={group} dispose={null} rotation={[0, -Math.PI / 2, 0]}>
      <group
        name="Null"
        position={[-1.12, 3.71, 1.96]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={0.01}
      />
      <group
        position={[0, -3.28, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <mesh
          ref={strokeRightRef}
          geometry={nodes.Sweep_1_2.geometry}
          material={strokeRightMaterial}
          position={[0, 0, 18.39]}
        />
      </group>
      <group
        position={[0, -3.03, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <mesh
          ref={strokeLeftRef}
          geometry={nodes.Sweep_2.geometry}
          material={strokeLeftMetarial}
          position={[0, 0, 16.36]}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
      <group
        position={[-0.08, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <group
          name="D"
          position={[170, -5.06, -11.79]}
          // rotation={[0.71, -0.12, 3]}
        >
          <mesh
            geometry={nodes.D_2.geometry}
            material={material}
            rotation={[0, 0, 0]}
            ref={DRef}
          />
        </group>
        <group name="i" position={[7.56, 0, 20.93]} ref={IRef}>
          <mesh
            geometry={nodes.I_1.geometry}
            material={material}
            position={[-7.56, 0, 0]}
          />
          <mesh
            geometry={nodes.I_2.geometry}
            material={material}
            position={[-7.56, 0, 0]}
          />
        </group>
        <group rotation={[0, -0.075, -0.2]} position={[-156.95, 8.59, -3.32]}>
          <mesh
            name="H"
            geometry={nodes.H.geometry}
            material={material}
            ref={HRef}
          />
        </group>
      </group>
    </group>
  );
}

// useGLTF.preload("/assets/models/hid_web.glb");
