import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { useRef } from "react";

export default function Light({ ...props }) {
  const lightRef = useRef();
  const { viewport } = useThree();
  useFrame(({ mouse, camera }) => {
    if (lightRef.current.position) {
      // Make the sphere follow the mouse
      var vector = new THREE.Vector3(
        mouse.x * viewport.width,
        mouse.y * viewport.height,
        0.5
      );
      vector.unproject(camera);
      var dir = vector.sub(camera.position).normalize();
      var distance = -camera.position.x / dir.x;
      var pos = camera.position.clone().add(dir.multiplyScalar(distance));
      //mouseMesh.position.copy(pos);

      lightRef.current.position.copy(
        new THREE.Vector3(pos.x - 2, pos.y, pos.z)
      );
    }
  });

  return <directionalLight ref={lightRef} color="#ff5925" />
}
