import { useThree } from "@react-three/fiber";
import { HemisphereLight } from "three";

export default function LeftLight() {
  const { scene } = useThree();
  const HLight = new HemisphereLight(0xff5925, 0xff5925, 0.1);
  HLight.translateY(5);
  HLight.translateX(-3);
  HLight.translateZ(2);

  scene.add(HLight);
  return null;
}
