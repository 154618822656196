import { useEffect } from "react";
import { UnsignedByteType, PMREMGenerator } from "three";
import { useThree } from "react-three-fiber";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

// or pass as a prop / import from project assets..
const HDRI = "/assets/environment.hdr";

// @ts-ignore: import/no-anonymous-default-export
export default function Environment({ onLoaded }) {
  const { gl, scene } = useThree();

  useEffect(() => {
    const pmremGenerator = new PMREMGenerator(gl);
    const loader = new RGBELoader();
    loader.setDataType(UnsignedByteType);
    pmremGenerator.compileEquirectangularShader();
    loader.load(HDRI, (texture) => {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.environment = envMap;
      // one can also set scene.background to envMap here
      onLoaded(true);

      texture.dispose();
      pmremGenerator.dispose();
    });
  }, [gl, scene, onLoaded]);

  return null;
};
