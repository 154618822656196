/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import { getMouseDegrees } from "../../shared/hooks/useMouseDegree";

function moveJoint(mouse, joint, degreeLimit = 15, positionX, isI = false) {
  let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);
  // console.log("x", degrees.x);
  if (!isI) {
    joint.rotation.yD = THREE.MathUtils.lerp(
      joint.rotation.yD || 0,
      degrees.x,
      0.1
    );
    joint.rotation.xD = THREE.MathUtils.lerp(
      joint.rotation.xD || 0,
      degrees.y,
      0.1
    );
  }

  joint.rotation.zD = THREE.MathUtils.lerp(
    joint.rotation.zD || 0,
    positionX < -7 ? degrees.x : -degrees.x * 3,
    0.1
  );
  // console.log(THREE.Math.degToRad(joint.rotation.yD))
  if (!isI) {
    joint.rotation.y = THREE.Math.degToRad(joint.rotation.yD);
    joint.rotation.x = THREE.Math.degToRad(joint.rotation.xD);
  }
  joint.rotation.z = THREE.Math.degToRad(joint.rotation.zD);
}

export default function Model({ isLoadedModel, setIsLoadedModel }) {
  const group = useRef();
  const HRef = useRef();
  const IRef = useRef();
  const DRef = useRef();
  const { size, viewport } = useThree();
  // useThree(({ camera }) => {
  //   camera.position.set(0, 2, 6);
  // });

  var material = new THREE.MeshStandardMaterial({
    // metalness: 1, // between 0 and 1
    // roughness: 0.5, // between 0 and 1
    metalness: 0.875, // between 0 and 1
    roughness: 0.289,
    // color: new Color(0x353535),
    // emissive: new Color(0x0),
  });
  const strokeRightRef = useRef();
  const strokeLeftRef = useRef();
  const { nodes, animations } = useGLTF("/assets/models/hid_mobile.glb");
  const { actions } = useAnimations(animations, group);
  const strokeRightMaterial = new THREE.MeshStandardMaterial({
    color: "#FF310D",
    // ...nodes.Sweep_1_2.material,
    transparent: true,
    side: THREE.DoubleSide,
    alphaTest: 0,
    opacity: 1,
    roughness: 0.5,
  });

  const strokeLeftMaterial = new THREE.MeshStandardMaterial({
    color: "#FF310D",
    // ...nodes.Sweep_2.material,
    transparent: true,
    side: THREE.DoubleSide,
    alphaTest: 0,
    opacity: 1,
    roughness: 0.5,
  });
  const imageLeft = document.createElement("img");
  const imageRight = document.createElement("img");
  let timeRight = 0;
  let isRightPause = true;
  let timeLeft = 0;
  let isLeftPause = false;
  const alphaMapRight = new THREE.Texture(imageRight);
  imageRight.onload = function () {
    alphaMapRight.needsUpdate = true;
  };
  imageRight.src =
    // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAGCAAAAADMTBDuAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABBJREFUCB1j3M3MaMXMgAQAECEA/lptYgQAAAAASUVORK5CYII=";
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAACpJREFUGJVjvH379n8GPIBl48aN+OQZGG1sbPCawMjAwIBXARNe8+mjAABz4ghlXDzN8gAAAABJRU5ErkJggg==";
  strokeRightMaterial.alphaMap = alphaMapRight;
  strokeRightMaterial.alphaMap.magFilter = THREE.NearestFilter;
  strokeRightMaterial.alphaMap.wrapT = THREE.RepeatWrapping;
  strokeRightMaterial.alphaMap.repeat.y = 1;

  const alphaMapLeft = new THREE.Texture(imageLeft);
  imageLeft.onload = function () {
    alphaMapLeft.needsUpdate = true;
  };
  imageLeft.src =
    // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAGCAAAAADMTBDuAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABBJREFUCB1j3M3MaMXMgAQAECEA/lptYgQAAAAASUVORK5CYII=";
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAACpJREFUGJVjvH379n8GPIBl48aN+OQZGG1sbPCawMjAwIBXARNe8+mjAABz4ghlXDzN8gAAAABJRU5ErkJggg==";
  strokeLeftMaterial.alphaMap = alphaMapLeft;
  strokeLeftMaterial.alphaMap.magFilter = THREE.NearestFilter;
  strokeLeftMaterial.alphaMap.wrapT = THREE.RepeatWrapping;
  strokeLeftMaterial.alphaMap.repeat.y = 1;
  useEffect(() => {
    actions["Cursor|CINEMA_4D_Main|Layer0"].play();
    actions["H_origin|CINEMA_4D_Main|Layer0"].play();
    actions["I_origin|CINEMA_4D_Main|Layer0"].play();
    actions["D_origin|CINEMA_4D_Main|Layer0"].play();
  });
  useEffect(() => {
    if (!isLoadedModel) {
      setIsLoadedModel(true);
    }
  }, [isLoadedModel, setIsLoadedModel]);
  useEffect(() => {
    if (window.Gyroscope) {
      let gyroscope = new window.Gyroscope({ frequency: 10 });

      gyroscope.addEventListener("reading", (e) => {
        // setState([gyroscope.x, gyroscope.y, gyroscope.z])
        if (gyroscope?.x && gyroscope?.y && gyroscope?.z) {
          const mouse = {
            x: size.width / 2 + (gyroscope?.x * size.width) / 2,
            y: size.height / 2 + (-gyroscope?.y * size.height) / 2,
          };
          if (HRef.current) {
            moveJoint(mouse, HRef.current, 15, gyroscope?.x * viewport.width);
          }
          if (IRef.current) {
            moveJoint(
              mouse,
              IRef.current,
              10,
              gyroscope?.x * viewport.width,
              true
            );
          }
          if (DRef.current) {
            moveJoint(mouse, DRef.current, 15, gyroscope?.x * viewport.width);
          }
        }
      });
      gyroscope.start();
    }
  }, [size.height, size.width, viewport.width]);
  useFrame((state, delta) => {
    if (strokeRightRef.current) {
      if (!isRightPause) {
        if (timeRight < 162) {
          strokeRightMaterial.opacity = 1;
          timeRight++;
        } else {
          strokeRightMaterial.opacity = 0;
          // if (!isLeftPause) {
          //   isLeftPause = true;
          //   setTimeout(() => {
          //     timeRight = 25;
          //     isLeftPause = false;
          //   }, 6000);
          // }
          isRightPause = true;
        }
        strokeRightRef.current.material.alphaMap.offset.y = -timeRight * 0.007;
      }
    }
    if (strokeLeftRef.current) {
      if (timeLeft < 162) {
        strokeLeftMaterial.opacity = 1;
        timeLeft++;
      } else {
        strokeLeftMaterial.opacity = 0;
        if (!isLeftPause) {
          isLeftPause = true;
          setTimeout(() => {
            timeRight = 25;
            isRightPause = false;
          }, 1000);
          setTimeout(() => {
            timeLeft = 25;
            isLeftPause = false;
          }, 6000);
        }
      }
      strokeLeftRef.current.material.alphaMap.offset.y = -timeLeft * 0.007;
    }
  });
  return (
    <group
      ref={group}
      dispose={null}
      scale={0.6}
      rotation={[0, -Math.PI / 2, 0]}
    >
      <group
        name="Cursor"
        position={[1.43, 2.32, 1.96]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={0.01}
      >
        {/* <mesh
          geometry={nodes.Plane.geometry}
          material={materials.OctDiffuse3}
          position={[-0.32, -0.75, 0.16]}
          rotation={[-Math.PI / 2, 0, 3.01]}
        /> */}
      </group>
      <group
        position={[0, -0.21, 0.16]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <mesh
          geometry={nodes.Sweep_1_2.geometry}
          material={strokeRightMaterial}
          ref={strokeRightRef}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          geometry={nodes.Sweep_2.geometry}
          material={strokeLeftMaterial}
          ref={strokeLeftRef}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        {/* <mesh
          geometry={nodes.Plane_1.geometry}
          material={nodes.Plane_1.material}
          position={[0, 0, -1477.32]}
          scale={2}
        /> */}
        {/* <mesh geometry={nodes.Plane_2.geometry} material={nodes.Plane_2.material} position={[0, 0, 564.29]} scale={2} /> */}
      </group>
      <group
        name="D_origin"
        position={[0, 0.07, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <mesh
          name="D"
          geometry={nodes.D.geometry}
          material={material}
          position={[72.77, -9.17, -97.55]}
          rotation={[0.13, -0.36, -0.19]}
          ref={DRef}
        />
      </group>
      <group
        name="I_origin"
        position={[0, 0.19, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
        ref={IRef}
      >
        <mesh
          geometry={nodes.I_1.geometry}
          material={material}
          position={[-154.18, -4.17, -329.79]}
          rotation={[0.17, 0, 0]}
        />
        <mesh
          geometry={nodes.I_2.geometry}
          material={material}
          position={[-153.44, -3.48, -328.74]}
          rotation={[0.17, 0, 0]}
        />
      </group>
      <group name="H_origin" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          name="H"
          geometry={nodes.H.geometry}
          material={material}
          position={[55, 127.33, -528.82]}
          rotation={[0.28, 0.12, 0.27]}
          ref={HRef}
        />
      </group>
      {/* <mesh
        geometry={nodes.Sphere.geometry}
        material={materials.OctDiffuse1}
        position={[-0.89, 2.96, 1.2]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      /> */}
    </group>
  );
}

// useGLTF.preload("/assets/models/hid_mobile.glb");
