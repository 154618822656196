import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useState } from "react";
import { WEBGL } from "./shared/libs/threejs/webgl";
import HIDWeb from "./components/common/Hid_web";
import Light from "./components/common/Light";
import HIDMobile from "./components/common/Hid_mobile";
import useWindowDimensions from "./shared/hooks/useWindowDimensions";
import Environment from "./components/common/Environment";
import { PerspectiveCamera } from "@react-three/drei";
import SunLight from "./components/common/SunLight";
import LeftLight from "./components/common/LeftLight";

function App() {
  const [isLoadedModel, setIsLoadedModel] = useState(false);
  const [isLoadedEnv, setIsLoadedEnv] = useState(false);

  useEffect(() => {
    if (WEBGL.isWebGLAvailable()) {
      // Initiate function or other initializations here
    } else {
      const warning = WEBGL.getWebGLErrorMessage();
      document.getElementById("container").appendChild(warning);
    }
  }, []);

  const dimension = useWindowDimensions();

  return (
    <>
      {!(isLoadedModel && isLoadedEnv) && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            fontSize: "24px",
            transform: "translate(-50%,-50%)",
          }}
        >
          <img src="/assets/load_icon.gif" alt="Loading..." />
        </div>
      )}
      <Canvas
        dpr={[1, 1.5]}
        style={{
          height: "100vh",
          opacity: isLoadedEnv ? 1 : 0,
          transition: "opacity 200ms ease-in",
        }}
      >
        <ambientLight insensity="0.5" />
        <pointLight position={[-2, 5, 4]} power={5} color="#FF5925" />
        <SunLight />
        <LeftLight />
        <Light />
        <PerspectiveCamera
          makeDefault
          rotation={[0, -Math.PI / 2, 0]}
          fov={40}
          position={dimension.width <= 768 ? [-10, 2.5, 0] : [-5, 0, 0]}
          near={1}
          far={1000}
        ></PerspectiveCamera>
        <Suspense fallback={null}>
          <Environment onLoaded={setIsLoadedEnv} />
          {dimension.width <= 768 ? (
            <HIDMobile
              isLoadedModel={isLoadedModel}
              setIsLoadedModel={setIsLoadedModel}
            />
          ) : (
            <HIDWeb
              isLoadedModel={isLoadedModel}
              setIsLoadedModel={setIsLoadedModel}
            />
          )}
        </Suspense>
      </Canvas>
    </>
  );
}

export default App;
